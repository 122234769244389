<template>
  <div class="comment d-flex mx-n2 px-2 py-75">
    <b-link :to="getMemberLocation()">
      <user-avatar :user="user" class="comment__avatar" />
    </b-link>
    <div class="comment__content mx-1">
      <b-link :to="getMemberLocation()">
        <h6 class="mb-0">
          {{ user.name }} {{ user.surname }}
        </h6>
        <small
          class="text-muted"
          :title="exactDate"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          {{ date }}
        </small>
      </b-link>
      <div v-if="!isEditing" class="mt-50">
        <content-with-anchors :content="comment.content" />
      </div>
      <comment-form
        v-else
        ref="commentForm"
        :source-key="comment.key"
        morph-type="comment"
        :item-type="itemType"
        :is-editing="true"
        :comment="comment"
        @update="isEditing = false"
        @cancel="isEditing = false"
      />
      <div class="comment__social mt-1 d-flex" :class="isAnswering?'mb-1':''">
        <button-like
          :liked="comment.likedByMember"
          :likes-count="comment.totalLikes"
          @click="$emit('toggle-like', comment)"
        />
        <button-comment
          v-if="comment.childCommentsCount"
          class="ml-1"
          :comments-count="comment.childCommentsCount"
          :active="isShowingResponses"
          @click="isShowingResponses = !isShowingResponses"
        />
        <div
          v-if="!leaf"
          variant="link"
          size="sm"
          class="text-uppercase button-reply pointer ml-1"
          @click="handleReply"
        >
          {{ $t('social.reply') }}
        </div>
      </div>
      <comment-form
        v-if="isAnswering && !leaf"
        ref="commentForm"
        :source-key="comment.key"
        morph-type="comment"
        :item-type="itemType"
        @created="$emit('commented', comment)"
        @cancel="isAnswering = false"
      />
      <div v-if="!leaf">
        <comments-list
          v-if="isShowingResponses"
          :source-key="comment.key"
          morph-type="comment"
          leaf
          class="mt-1"
          @delete="deleteComment"
        />
      </div>
    </div>
    <div v-if="isLoggedMember" class="comment__action-buttons position-absolute">
      <button-edit
        @click="isEditing=true"
      />
      <button-delete
        class="ml-75"
        @click="handleRemoveItem"
      />
    </div>
  </div>
</template>

<script>
import UserAvatar from '@core/components/user/UserAvatar.vue';
import ButtonLike from '@core/components/social/ButtonLike.vue';
import ButtonEdit from '@core/components/social/ButtonEdit.vue';
import ButtonDelete from '@core/components/social/ButtonDelete.vue';
import ButtonComment from '@core/components/social/ButtonComment.vue';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
import CommentForm from './CommentForm.vue';
import CommentsList from './CommentsList.vue';

export default {
  name: 'CommentItem',
  components: {
    UserAvatar,
    ButtonLike,
    ButtonComment,
    ContentWithAnchors,
    CommentForm,
    CommentsList,
    ButtonDelete,
    ButtonEdit,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    leaf: Boolean,
    itemType: {
      type: String,
      default: null,
    },
    sourceKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowingResponses: false,
      isAnswering: false,
      isEditing: false,
    };
  },
  computed: {
    user() {
      return this.comment.createdBy;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isLoggedMember() {
      return this.$store.getters.loggedMember.key === this.comment.createdBy.key;
    },
    exactDate() {
      const date = new Date(this.comment.createdAt);
      return date.toLocaleString();
    },
    date() {
      return this.$moment(this.comment.createdAt).locale(this.locale).fromNow();
    },
  },
  methods: {
    async handleReply() {
      this.isAnswering = !this.isAnswering;
      this.isShowingResponses = this.isAnswering;
      if (this.isAnswering) {
        await this.$nextTick();
        this.$refs.commentForm.focus();
      }
    },
    deleteComment() {
      --this.comment.childCommentsCount;
    },
    handleRemoveItem(response) {
      if (response === true) {
        try {
          this.$store.dispatch('deleteComment', {
            item: {
              storedKey: this.sourceKey,
              itemType: 'comments',
              requestConfig: {
                key: this.comment.key,
              },
            },
          });
          this.$emit('deleted');

          this.notifySuccess(this.$t('streaming.messages.success-delete'));
          this.getInitialData();
        } catch {
          this.notifyError(this.$t('streaming.messages.error-delete'));
        }
      }
    },
    getMemberLocation() {
      if (!this.user.name) {
        return null;
      }

      return {
        name: 'member-details',
        params: {
          username: this.user.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.button-reply {
  color: $dark;
  @include hover() {
    color: $primary;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($dark, .5);
  }

  &.disabled,
  &:disabled {
    color: $text-muted;
  }

/*   &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background: $primary;
    color: $white;
  } */

  &--active {
    color: $primary;
    @include hover() {
      color: $dark;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
    }

    &.disabled,
    &:disabled {
      color: $text-muted;
    }

/*     &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background: $dark;
      color: $white;
    } */
  }
}
  .comments-count {
    display: inline-block;
    line-height: 1.5;
    margin-left: calc($spacer / 2);
  }
  .comment {
    &__content {
      flex-grow: 1;
    }
    &__action-buttons{
      display:none;
      right: 2.5rem;
      :hover > & {
        display:flex;
      }
    }
    &:hover{
      background-color: #f9f9f9;
    }
  }
</style>
