<template>
  <div
    class="p-0 button-comment pointer d-flex align-items-center justify-content-center"
    :class="{ 'button-comment--active': active }"
    variant="link"
    @click="toggleLike"
  >
    <feather-icon icon="MessageCircleIcon" size="18" />
    <small v-if="commentsCount" class="comments-count">
      {{ commentsCount }}
    </small>
    </d>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'ButtonComment',
  components: { BButton },
  props: {
    active: Boolean,
    commentsCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    toggleLike(event) {
      this.$emit('update:liked', !this.liked);
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.button-comment {
  color: $dark;
  @include hover() {
    color: $primary;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($dark, .5);
  }

  &.disabled,
  &:disabled {
    color: $text-muted;
  }
  &--active {
    color: $primary;
    @include hover() {
      color: $dark;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
    }

    &.disabled,
    &:disabled {
      color: $text-muted;
    }
  }

  .comments-count {
    display: inline-block;
    line-height: 1.5;
    margin-left: calc($spacer / 2);
  }
}
</style>
