<template>
  <div
    class="p-0 button-delete d-flex justify-content-center pointer"
    variant="link"
    @click="isUnshareModalVisible=true"
  >
    <feather-icon icon="Trash2Icon" size="18" />
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="comment"
      @remove-item="handleRemoveItem"
    />
  </div>
</template>

<script>
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';

export default {
  name: 'ButtonDelete',
  components: { UnshareModal },
  data() {
    return {
      isUnshareModalVisible: false,
    };
  },
  methods: {
    handleRemoveItem(response) {
      this.$emit('click', response);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.button-delete {
  color: $dark;
  @include hover() {
    color: $primary;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($dark, .5);
  }

  &.disabled,
  &:disabled {
    color: $text-muted;
  }
  &--liked {
    color: $primary;
    @include hover() {
      color: $dark;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
    }

    &.disabled,
    &:disabled {
      color: $text-muted;
    }
  }

  .likes-count {
    display: inline-block;
    line-height: 1.5;
    margin-left: calc($spacer / 2);
  }
}
</style>
