<template>
  <div
    class="p-0 button-edit d-flex justify-content-center pointer"
    variant="link"
    @click="handleEditItem"
  >
    <feather-icon icon="Edit2Icon" size="18" />
  </div>
</template>

<script>

export default {
  name: 'ButtonEdit',
  data() {
    return {
      isUnshareModalVisible: false,
    };
  },
  methods: {
    handleEditItem(response) {
      this.$emit('click', response);
      this.$emit('change', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.button-edit {
  color: $dark;
  @include hover() {
    color: $primary;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($dark, .5);
  }

  &.disabled,
  &:disabled {
    color: $text-muted;
  }
  &--liked {
    color: $primary;
    @include hover() {
      color: $dark;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
    }

    &.disabled,
    &:disabled {
      color: $text-muted;
    }
  }

  .likes-count {
    display: inline-block;
    line-height: 1.5;
    margin-left: calc($spacer / 2);
  }
}
</style>
